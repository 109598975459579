import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './components/Home';
import About from './components/About';
import FAQ from './components/FAQ';
import Gallery from './components/Gallery';
import Terms from './components/Terms';
import Contact from './components/Contact';
import Upload from './components/Upload';
import Approve from './components/Approve';
import EditOutput from './components/EditOutput';
import PrivacyPolicy from './components/PrivacyPolicy';
import Success from './components/Success';
import ReactGA from 'react-ga4';
import { CookieConsent } from 'react-cookie-consent';
import './App.css';

function App() {
  return (
    <Router>
      <AppContent />
      <CookieConsent 
        // disableStyles={true}
        enableDeclineButton
        onAccept={() => {
          window.location.reload();
          
          console.log("Cookie accepted");
        }}
        onDecline={() => {
          console.log("Cookie declined");
        }}
        // debug={true}
        location="bottom"

        buttonText="I understand"
        cookieName="engravemyrideCookieConsent"
        containerClasses="cookie-consent"
        buttonClasses="cookie-consent-button"
        // flipButtons
        declineButtonClasses="cookie-consent-button"
        declineButtonText="No thanks"
        expires={150}
      >
        This website uses cookies to enhance the user experience.{" "}
        <span style={{ fontSize: "var(--small-size)" }}>
          By continuing to use this site, you agree to our use of cookies.
        </span>
      </CookieConsent>
    </Router>
  );
}


function AppContent() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
    const routeTitles = {
      '/': 'EngraveMyRide - Custom Vehicle Keychains',
      '/upload': 'Upload Your Vehicle Image | EngraveMyRide',
      '/approve': 'Approve Your Design | EngraveMyRide',
      '/FAQ': 'Frequently Asked Questions | EngraveMyRide',
      '/gallery': 'Customer Gallery | EngraveMyRide',
      '/about': 'About Us | EngraveMyRide',
      '/contact': 'Contact Us | EngraveMyRide',
      '/edit_output': 'Customize Your Keychain | EngraveMyRide',
      '/terms': 'Terms and Conditions | EngraveMyRide',
      '/privacy': 'Privacy Policy | EngraveMyRide',
      '/success': 'Order Successful | EngraveMyRide',
    };

    const routeDescriptions = {
      '/': 'Create personalized laser-engraved leather keychains from your vehicle images. Turn your ride into a timeless keepsake.',
      '/upload': 'Upload your vehicle image to start creating your custom keychain with EngraveMyRide.',
      '/approve': 'Review and approve your vehicle image for your custom keychain from EngraveMyRide.',
      '/FAQ': 'Find answers to frequently asked questions about EngraveMyRide\'s custom vehicle keychains.',
      '/gallery': 'View our gallery of custom vehicle keychains created by EngraveMyRide customers.',
      '/about': 'Learn about EngraveMyRide and our passion for creating custom vehicle keychains.',
      '/contact': 'Get in touch with EngraveMyRide for questions or support regarding your custom keychain.',
      '/edit_output': 'Customize your vehicle keychain design with EngraveMyRide\'s easy-to-use tools.',
      '/terms': 'Read EngraveMyRide\'s terms and conditions for using our custom keychain services.',
      '/privacy': 'Learn about EngraveMyRide\'s privacy policy and how we protect your personal information.',
    };

    document.title = routeTitles[location.pathname] || 'EngraveMyRide';
    
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', routeDescriptions[location.pathname] || routeDescriptions['/']);
    }
  }, [location]);

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/upload" element={<Upload/>} />
        <Route path="/approve" element={<Approve/>} />
        <Route path="/FAQ" element={<FAQ/>} />
        <Route path="/gallery" element={<Gallery/>} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/edit_output" element={<EditOutput/>} />
        <Route path="/terms" element={<Terms/>} />
        <Route path="/privacy" element={<PrivacyPolicy/>} />
        <Route path="/success" element={<Success/>} />
      </Routes>
    </Layout>
  );
}

export default App;