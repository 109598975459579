import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../styles/Upload.css';
import uploadIcon from '../assets/upload-icon.svg';

const UPLOAD_URL = `${process.env.REACT_APP_BACKEND_URL}/upload`;

function Upload() {
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [agreePrivacy, setAgreePrivacy] = useState(false);
  const navigate = useNavigate();
  const [previewUrl, setPreviewUrl] = useState(null);
  const [scanning, setScanning] = useState(false);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPreviewUrl(null);
    }
  };

  const handleUpload = async () => {
    if (!selectedFile || !agreeTerms || !agreePrivacy) return;

    setScanning(true);
    setLoading(true);
    
    // Simulate scanning animation
    await new Promise(resolve => setTimeout(resolve, 3000));

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const response = await axios.post(UPLOAD_URL, formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.status === 200) {
        const uuid = response.headers['x-uuid'];
        localStorage.setItem('uuid', uuid);
        console.log('uuid', uuid);
        navigate('/approve');
      } else {
        alert('Upload failed. Please try again.');
      }
    } catch (error) {
      console.error('Upload error:', error);
      alert('Upload failed. Please check your internet connection and try again.');
    } finally {
      setLoading(false);
      setScanning(false);
    }
  };

  const isUploadDisabled = !selectedFile || !agreeTerms || !agreePrivacy || loading;


  return (
    <div className="upload-container">
      <h1>Upload Your Vehicle Image</h1>
      <p>
        Start designing your custom keychain by uploading a photo of your ride.
        We'll handle the background removal for you.
      </p>
      {previewUrl && (
        <div className={`image-preview ${scanning ? 'scanning' : ''}`}>
          <img src={previewUrl} alt="Preview" />
          {scanning && <div className="scan-line"></div>}
        </div>
      )}
      <div className="file-input-container">
        <input
          type="file"
          id="file-input"
          className="file-input"
          onChange={handleFileChange}
          disabled={loading}
          accept="image/*"
        />
        <label htmlFor="file-input" className="file-input-label">
          <img src={uploadIcon} alt="Upload Icon" className="upload-icon" />
          <span>{selectedFile ? selectedFile.name : 'Select Image'}</span>
        </label>
      </div>
      <p className="upload-hint">
        Accepted formats: JPEG, PNG. Max size: 10MB.
      </p>
      <div className="agreement-container">
        <label className="agreement-label">
          <input
            type="checkbox"
            checked={agreeTerms}
            onChange={(e) => setAgreeTerms(e.target.checked)}
          />
          I agree to the <a href="/terms" target="_blank">Terms and Conditions</a>
        </label>
        <label className="agreement-label">
          <input
            type="checkbox"
            checked={agreePrivacy}
            onChange={(e) => setAgreePrivacy(e.target.checked)}
          />
          I agree to the <a href="/privacy" target="_blank">Privacy Policy</a>
        </label>
      </div>
      <button
        className="upload-button"
        onClick={handleUpload}
        disabled={isUploadDisabled}
      >
        {loading ? 'Uploading...' : 'Upload and Continue'}
      </button>
      <p className="upload-tips">
        Need help? <a href="/faq">Check our image guidelines</a> for best results.
      </p>
    </div>
  );
}

export default Upload;