import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Layout.css'; // Import the new CSS file
import logo from '../assets/logo.png'; // Placeholder for your logo

function Layout({ children }) {
  return (
    <div className="website-layout">
      <header className="site-header">
        <nav className="navigation">
          <div className="logo-container">
            <Link to="/">
              <img src={logo} alt="Engrave My Ride Logo" className="logo" />
            </Link>
          </div>
          <ul className="nav-links">
            <li><Link to="/">Home</Link></li>
            {/* <li><Link to="/how-it-works">How it works</Link></li> */}
            <li><Link to="/upload">Create Your Own</Link></li>
            <li><Link to="/gallery">Gallery</Link></li>
            <li><Link to="/about">About Us</Link></li>
            <li><Link to="/faq">FAQ</Link></li>
            <li><Link to="/contact">Contact</Link></li>
          </ul>
        </nav>
      </header>
      <main>{children}</main>
      <footer className="site-footer">
        <p>&copy; 2024 Engrave My Ride. All rights reserved.</p>
        <p>
          <Link to="/privacy">Privacy Policy</Link> | 
          <Link to="/terms">Terms and Conditions</Link>
        </p>
        <p>
          <Link to="#" onClick={() => {
            document.cookie = "engravemyrideCookieConsent=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            window.location.reload();
          }}>Reset Cookie Settings</Link>
        </p>
      </footer>
    </div>
  );
}

export default Layout;
