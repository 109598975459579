import React from 'react';
import '../styles/Home.css'; // Ensure this CSS file includes the new styles
import { useNavigate } from 'react-router-dom';
import { InstagramEmbed } from 'react-social-media-embed';
import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import { Cookies } from "react-cookie-consent";

import productImg1 from '../assets/hero/hero1.png'; // Placeholder images for featured products
import productImg2 from '../assets/hero/hero2.JPG';
import productImg3 from '../assets/hero/hero3.png';

// Add imports for your background images
import bgImage1 from '../assets/hero/hero1.png';
import bgImage2 from '../assets/hero/hero2.JPG';
import bgImage3 from '../assets/hero/hero3.png';
import bgImage4 from '../assets/hero/hero4.png';  
import bgImage5 from '../assets/hero/hero5.png';
import bgImage6 from '../assets/hero/hero6.png';
import bgImage7 from '../assets/hero/hero7.png';

function Home() {
  const navigate = useNavigate();
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": "EngraveMyRide",
    "url": "https://www.engravemyride.com",
  };
  const [cookiesAccepted, setCookiesAccepted] = useState(false);
  const [currentBgIndex, setCurrentBgIndex] = useState(0);
  const bgImages = [bgImage1, bgImage2, bgImage3, bgImage4, bgImage5, bgImage6, bgImage7];

  useEffect(() => {
    const consentValue = Cookies.get('CookieConsent');
    console.log('consentValue', consentValue);
    setCookiesAccepted(consentValue === 'true');
  }, []);


  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBgIndex((prevIndex) => (prevIndex + 1) % bgImages.length);
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, [bgImages.length]);


  return (
    <div className="home">
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      {/* Hero Section */}
      <section className="hero" style={{
        backgroundImage: `url(${bgImages[currentBgIndex]})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        transition: 'background-image 1s ease-in-out'
      }}>
        <div className="hero-content">
          <div className="hero-text">
            <h1 className="hero-title">Turn Your Ride into a Timeless Keepsake</h1>
            <p className="hero-subtitle">
              Personalized laser-engraved leather keychains crafted from your own vehicle images.
            </p>
            <button className="hero-cta" onClick={() => navigate('/upload')}>
              Design Yours Now<br />For €20
            </button>
          </div>
        </div>
      </section>

      {/* How It Works Section */}
      <section className="how-it-works">
        <h2>How It Works</h2>
        <div className="steps">
          <div className="step">
            <h3>Upload an Image</h3>
            <p>
              Upload a photo of your vehicle, and our tool will automatically remove the background.
              If you're not satisfied with the result, you can upload a different image.
              <a href="/faq"> Check our FAQ</a> for tips on choosing the best images.
            </p>
          </div>
          <div className="step">
            <h3>Customize Design</h3>
            <p>
              Choose from five contrast settings and four attachment options to personalize your keychain.
              Adjust until it's just right.
            </p>
          </div>
          <div className="step">
            <h3>Secure Checkout</h3>
            <p>
              Once you're happy with your design, proceed to a secure checkout via Stripe.
              Your payment details are protected, and your unique keychain will be on its way!
            </p>
          </div>
        </div>
        <button className="section-cta" onClick={() => navigate('/upload')}>Start Designing</button>
      </section>

      {/* Featured Products Section */}
      <section className="featured-products">
        <h2>Popular Design Styles</h2>
        <div className="product-gallery">
          <div className="product-item">
            <img src={productImg1} alt="Single-Sided Engraving" />
            <h3>Single-Sided Engraving</h3>
            <p>
              A classic choice featuring your vehicle engraved on premium leather on a single side.
            </p>
          </div>
          <div className="product-item">
            <img src={productImg2} alt="Double-Sided Engraving" />
            <h3>Double-Sided Engraving</h3>
            <p>
              Showcase your vehicle on two sides creating an even more unique design.
            </p>
          </div>
          <div className="product-item">
            <img src={productImg3} alt="Double-Sided Engraving with Text" />
            <h3>Double-Sided Engraving with Text</h3>
            <p>
              Showcase your vehicle on one side and add a personal message on the other.
            </p>
          </div>
        </div>
        <button className="section-cta" onClick={() => navigate('/upload')}>Explore All Styles</button>
      </section>

      {/* Testimonials Section */}
      <section className="gallery-list">
        <h2>Customer Creations</h2>
        <div className="gallery-list">
        {console.log(cookiesAccepted)}
          {(
            <>
              <div className="gallery-item">    
                <InstagramEmbed url="https://www.instagram.com/p/C6WIFOjsyn6/" width={328} />
              </div>
              <div className="gallery-item">
                <InstagramEmbed url="https://www.instagram.com/p/C6Yd6QHM4kT/" width={328} />
              </div>
              <div className="gallery-item">
                <InstagramEmbed url="https://www.instagram.com/p/C56NAgXo7za/" width={328} />
              </div>
            </>
          )}
        </div>
      </section>
    </div>
  );
}

export default Home;